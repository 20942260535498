@import "variables.scss";

div.card {
  & > p:first-child {
    margin-top: 0;
  }
  margin-block: 0 $spacing-vertical-base;
  display: block;
  text-align: left;
  padding: $px-30;
  background: $xp-100;
  border-radius: $px-10;
  position: relative;
  box-shadow: 3px 5px 10px 0 rgba(0, 0, 0, 0.05);
  &.grid-xp {
    display: grid;
    align-items: center;
    gap: 0;
  }
  &.orange-bg {
    background: $xp-gra;
    a {
      &:hover {
        color: $xp-100;
      }
    }
  }
  &.std-ratio {
    aspect-ratio: 16/9;
  }
  &.text-center {
    text-align: center;
  }
  // &.md {
  //   max-width: 28rem;
  // }
  // &.enforce-min {
  //   min-width: 22rem;
  // }
  &.with-pointer {
    cursor: pointer;
  }
  h1:first-child {
    margin-top: 0;
  }
  h2:first-child {
    margin-top: 0;
  }
  h3:first-child {
    margin-top: 0;
  }
}

div.card + div.card {
  margin-top: $standard-spacer;
}

.dark-mode .card {
  background: $xp-000;
}
