@import "./variables.scss";

::placeholder,
option.placeholder {
  color: $xp-040;
}

form {
  display: flex;
  flex-direction: column;
  &.horizontal {
    flex-direction: row;
  }
  position: relative;
  background-color: inherit;
  fieldset {
    position: relative;
    background-color: inherit;
    border: 0;
    &.is-closed {
      display: none;
      visibility: hidden;
    }
    legend {
      font-weight: 500;
    }
  }
  div.form-control {
    display: block;
    position: relative;
    margin-block: $spacing-vertical-base 0;
    &.m-block-0 {
      margin-block: 0 0;
    }
    div.form-label {
      &.flex-layout {
        justify-content: flex-end;
        p {
          white-space: nowrap;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
    label {
      width: 100%;
      font-weight: 600;
      display: block;
      &.disabled {
        color: $xp-080;
      }
    }
    input,
    select,
    textarea {
      width: 100%;
      display: block;
      padding: 0 calc($px-15 / 2);
      border: 0;
      outline: 0;
      &[disabled] {
        background-color: inherit;
        color: $xp-090;
      }
      &[type="checkbox"] {
        cursor: pointer;
      }
      //&:focus:not(input[type="checkbox"]) {
      //  border: 0;
      //}
    }
  }

  div.submit-warning {
    display: none;
    position: absolute;
    right: 0;
    // &.invalid-after-submit {
    // display: inline-block;
    // color: $xp-dan;
    // right: calc($standard-spacer * 2);
    // }
  }

  div.form-errors {
    p {
      margin-top: 0;
      margin-bottom: 0;
      color: $xp-dan;
    }
  }
  div.form-info {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

*.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

div.flex-layout {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: $px-15;
  align-items: center;
  &.no-gap {
    gap: 0;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.layout-vertical {
    flex-direction: column;
  }
  & > div {
    flex: 1;
    border: 1px solid black;
    border-radius: 3px;
    padding: 20px;
  }
  &.wrap {
    flex-wrap: wrap;
  }
}
