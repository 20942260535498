#xperience-player #player {
  --fp-brand-color: #e69107;
  position: relative;
  top: 0;

  .fp-engine {
    transition: height 0.3s;
  }
  &.is-playing:not(.ui-visible) flowplayer-ui {
    display: none;
  }

  &:not(.is-promo) {
    &.ui-visible:not(.is-fullscreen),
    &:not(.is-playing) {
      flowplayer-control {
        margin-bottom: 450px;
      }
    }
  }

  flowplayer-volume-bar {
    width: 5em;
    transition: width 0.3s;
  }

  &.is-muted:not(.no-mute) flowplayer-volume-bar {
    width: 0;
  }

  flowplayer-header,
  flowplayer-control {
    padding: 33px;
  }

  flowplayer-timeline-bar {
    height: 2px;
    margin: 20px;
  }

  flowplayer-header-right-zone {
    margin-right: 35px;
  }

  flowplayer-timeline-bar:not(:hover) .fp-timestamp {
    display: none !important;
  }

  // flowplayer-chromecast-icon.fp-cast-button {
  //   opacity: 1;
  //   display: inline-block;

  //   google-cast-launcher {
  //     display: initial!important;
  //     fill: white;
  //   }
  // }

  .fp-progress:after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $xp-gra;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    box-shadow: 0 3px 5px #00000026;
    cursor: grab;
    transition: all 0.2s ease-in-out;
    z-index: 1;
  }

  .fp-progress:hover:after {
    width: 15px;
    height: 15px;
  }
}

.is-paused:not(.is-touched):not(.is-starting) .fp-play {
  width: 50px;
  height: 50px;
}

.fp-airplay {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
