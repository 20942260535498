@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap");

[class*="mci-"],
[class*="mci-"]:before {
  //vertical-align: middle;
  font-family: "mci" !important;
  font-weight: 300;
}

html {
  font-size: $typography-modular-html-base;
}

body {
  font-size: $typography-modular-base;
  font-family: $font-family;
  font-weight: 400;
  line-height: $spacing-vertical-1;
  color: $xp-000;
  background: radial-gradient($xp-020 25%, $xp-000 100%) center center;
}

header {
  margin-block: 0 0;
}

footer {
  margin-block: 0 0;
}

main {
  margin-block: 0 0;
  min-width: $body-min-width;
}

section {
  margin-block: 0 0;
}

h1,
.h1 {
  // font-size: $typography-modular-1;
  line-height: $spacing-vertical-2;
  font-weight: 800;
  margin-block: $spacing-vertical-base 0;
  font-size: clamp($typography-modular-2, 4vw, $typography-modular-1);
}

h2,
.h2 {
  // font-size: $typography-modular-2;
  line-height: $spacing-vertical-1-5;
  font-weight: 600;
  margin-block: $spacing-vertical-base 0;
  font-size: clamp($typography-modular-3, 3.5vw, $typography-modular-2);
}

h3,
.h3 {
  // font-size: $typography-modular-3;
  line-height: $spacing-vertical-1-5;
  font-weight: 600;
  margin-block: $spacing-vertical-base 0;
  font-size: clamp($typography-modular-4, 3vw, $typography-modular-3);
}

h4,
.h4 {
  font-size: $typography-modular-4;
  line-height: $spacing-vertical-1;
  font-weight: 600;
  margin-block: $spacing-vertical-base 0;
}

h5,
.h5 {
  font-size: $typography-modular-5;
  line-height: $spacing-vertical-1;
  font-weight: 600;
  margin-block: $spacing-vertical-base 0;
}

h6,
.h6 {
  font-size: $typography-modular-6;
  line-height: $spacing-vertical-1;
  font-weight: 600;
  margin-block: $spacing-vertical-base 0;
}

p,
.body {
  font-size: $typography-modular-base;
  font-weight: 400;
  margin-block: $spacing-vertical-base 0;
  color: inherit;
}

p.smaller {
  font-size: smaller;
}

a {
  font-size: inherit;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
  letter-spacing: -0.015rem;
  &:hover {
    text-decoration: underline;
    color: $xp-pri;
  }
  &.nav {
    font-weight: 400;
    color: $xp-040;
  }
  &:has(span[class*="mci"]) {
    display: flex;
    column-gap: calc($px-03 * 2.5);
    &:hover {
      span {
        color: $xp-pri;
      }
    }
  }
}

ul {
  font-size: $typography-modular-base;
  font-weight: 400;
  margin-block: $spacing-vertical-base 0;
  list-style-type: disc;
  list-style-position: inside;
  color: inherit;
}

ol {
  font-size: $typography-modular-base;
  font-weight: 400;
  margin-block: $spacing-vertical-base 0;
  list-style-position: inside;
  color: inherit;
}

li {
  font-size: $typography-modular-base;
  font-weight: 400;
  margin-block: 0 0;
  color: inherit;
}

nav {
  margin-block: 0 0;
  a {
    text-decoration: none;
  }
}

table {
  * {
    font-size: $typography-modular-base;
  }
}

form,
fieldset {
  margin-block: 0 0;
}

input,
select {
  font-size: $typography-modular-base;
  height: $spacing-vertical-1-5;
  line-height: $spacing-vertical-1-5;
  color: inherit;
}

textarea {
  font-size: $typography-modular-base;
  line-height: $spacing-vertical-1;
  color: inherit;
}

label,
*.label {
  font-size: $typography-modular-base;
  line-height: $spacing-vertical-1-5;
  color: $xp-000;
}

button,
.button {
  font-size: $typography-modular-base;
  font-weight: 600;
}

strong,
.strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

*.monospace {
  font-family: monospace;
}

*.show-pointer {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $xp-000;
}

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
  color: $xp-100;
}

.dark-mode p {
  color: $xp-060;
}

.dark-mode a {
  color: $xp-060;
}

a:hover {
  cursor: pointer;
  color: $xp-pri;
}
