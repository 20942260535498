@import "variables.scss";

.loader {
  width: 48px;
  height: 48px;
  border: 4px solid $xp-pri;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  &.light {
    border-color: $xp-100;
    border-bottom-color: transparent;
  }
  &.dark {
    border-color: $xp-040;
    border-bottom-color: transparent;
  }
  &.x-sm {
    width: 1.25rem;
    height: 1.25rem;
    border-width: 2px;
  }
  &.sm {
    width: 48px;
    height: 48px;
  }
  &.md {
    width: $px-30;
    height: $px-30;
  }
  &.lg {
    width: calc($px-30 * 2);
    height: calc($px-30 * 2);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
