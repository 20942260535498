@font-face {
  font-family: 'mci';
  src:  url('./mci.eot?8b1z2');
  src:  url('./mci.eot?8b1z2#iefix') format('embedded-opentype'),
        url('./mci.ttf?8b1z2') format('truetype'),
        url('./mci.woff?8b1z2') format('woff'),
        url('./mci.svg?8b1z2#mci') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mci-youtube:before {
  content: "\e900";
}
.mci-yubikey:before {
  content: "\e901";
}
.mci-yubikey-solid:before {
  content: "\e902";
}
.mci-zoom-in:before {
  content: "\e903";
}
.mci-zoom-in-solid:before {
  content: "\e904";
}
.mci-zoom-out:before {
  content: "\e905";
}
.mci-zoom-out-solid:before {
  content: "\e906";
}
.mci-yahoo-open-source:before {
  content: "\e907";
}
.mci-yamas:before {
  content: "\e908";
}
.mci-women-solid:before {
  content: "\e909";
}
.mci-wallet:before {
  content: "\e90a";
}
.mci-wallet-solid:before {
  content: "\e90b";
}
.mci-warning:before {
  content: "\e90c";
}
.mci-warning-solid:before {
  content: "\e90d";
}
.mci-whatsapp:before {
  content: "\e90e";
}
.mci-wifi:before {
  content: "\e90f";
}
.mci-wifi-disable:before {
  content: "\e910";
}
.mci-wifi-disable-solid:before {
  content: "\e911";
}
.mci-wifi-error:before {
  content: "\e912";
}
.mci-wifi-error-solid:before {
  content: "\e913";
}
.mci-wifi-solid:before {
  content: "\e914";
}
.mci-women:before {
  content: "\e915";
}
.mci-voucher-solid:before {
  content: "\e916";
}
.mci-value-all:before {
  content: "\e917";
}
.mci-value-all-solid:before {
  content: "\e918";
}
.mci-value-average:before {
  content: "\e919";
}
.mci-value-average-solid:before {
  content: "\e91a";
}
.mci-value-exclude:before {
  content: "\e91b";
}
.mci-value-exclude-solid:before {
  content: "\e91c";
}
.mci-value-maximum:before {
  content: "\e91d";
}
.mci-value-maximum-solid:before {
  content: "\e91e";
}
.mci-value-minimum:before {
  content: "\e91f";
}
.mci-value-minimum-solid:before {
  content: "\e920";
}
.mci-value-sum:before {
  content: "\e921";
}
.mci-value-sum-solid:before {
  content: "\e922";
}
.mci-verizon-check:before {
  content: "\e923";
}
.mci-vespa:before {
  content: "\e924";
}
.mci-vials:before {
  content: "\e925";
}
.mci-vials-solid:before {
  content: "\e926";
}
.mci-video:before {
  content: "\e927";
}
.mci-video-solid:before {
  content: "\e928";
}
.mci-virtual-machine:before {
  content: "\e929";
}
.mci-virtual-machine-solid:before {
  content: "\e92a";
}
.mci-virus:before {
  content: "\e92b";
}
.mci-viruses:before {
  content: "\e92c";
}
.mci-viruses-solid:before {
  content: "\e92d";
}
.mci-virus-none:before {
  content: "\e92e";
}
.mci-virus-none-solid:before {
  content: "\e92f";
}
.mci-virus-solid:before {
  content: "\e930";
}
.mci-voltage:before {
  content: "\e931";
}
.mci-voltage-solid:before {
  content: "\e932";
}
.mci-volume-down:before {
  content: "\e933";
}
.mci-volume-down-solid:before {
  content: "\e934";
}
.mci-volume-mute:before {
  content: "\e935";
}
.mci-volume-mute-solid:before {
  content: "\e936";
}
.mci-volume-off:before {
  content: "\e937";
}
.mci-volume-off-solid:before {
  content: "\e938";
}
.mci-volume-up:before {
  content: "\e939";
}
.mci-volume-up-solid:before {
  content: "\e93a";
}
.mci-voucher:before {
  content: "\e93b";
}
.mci-user-virus-solid:before {
  content: "\e93c";
}
.mci-unarchive:before {
  content: "\e93d";
}
.mci-unarchive-solid:before {
  content: "\e93e";
}
.mci-undo:before {
  content: "\e93f";
}
.mci-undo-solid:before {
  content: "\e940";
}
.mci-ungroup:before {
  content: "\e941";
}
.mci-ungroup-solid:before {
  content: "\e942";
}
.mci-unlink:before {
  content: "\e943";
}
.mci-unlink-solid:before {
  content: "\e944";
}
.mci-upload:before {
  content: "\e945";
}
.mci-upload-solid:before {
  content: "\e946";
}
.mci-urgent:before {
  content: "\e947";
}
.mci-urgent-solid:before {
  content: "\e948";
}
.mci-user-add:before {
  content: "\e949";
}
.mci-user-add-solid:before {
  content: "\e94a";
}
.mci-user-admin-network:before {
  content: "\e94b";
}
.mci-user-admin-network-solid:before {
  content: "\e94c";
}
.mci-user-canonical:before {
  content: "\e94d";
}
.mci-user-canonical-solid:before {
  content: "\e94e";
}
.mci-user-cough:before {
  content: "\e94f";
}
.mci-user-cough-solid:before {
  content: "\e950";
}
.mci-user-data:before {
  content: "\e951";
}
.mci-user-data-solid:before {
  content: "\e952";
}
.mci-user-doctor:before {
  content: "\e953";
}
.mci-user-doctor-solid:before {
  content: "\e954";
}
.mci-user-group:before {
  content: "\e955";
}
.mci-user-group-solid:before {
  content: "\e956";
}
.mci-user-id:before {
  content: "\e957";
}
.mci-user-id-solid:before {
  content: "\e958";
}
.mci-user-in-hospital:before {
  content: "\e959";
}
.mci-user-in-hospital-solid:before {
  content: "\e95a";
}
.mci-user-in-house:before {
  content: "\e95b";
}
.mci-user-in-house-solid:before {
  content: "\e95c";
}
.mci-user-location:before {
  content: "\e95d";
}
.mci-user-location-solid:before {
  content: "\e95e";
}
.mci-user-lock:before {
  content: "\e95f";
}
.mci-user-lock-solid:before {
  content: "\e960";
}
.mci-user-mask:before {
  content: "\e961";
}
.mci-user-mask-solid:before {
  content: "\e962";
}
.mci-user-minus:before {
  content: "\e963";
}
.mci-user-minus-solid:before {
  content: "\e964";
}
.mci-user-nurse:before {
  content: "\e965";
}
.mci-user-nurse-solid:before {
  content: "\e966";
}
.mci-user-org-chart:before {
  content: "\e967";
}
.mci-user-org-chart-solid:before {
  content: "\e968";
}
.mci-user-profile-circle:before {
  content: "\e969";
}
.mci-user-profile-circle-solid:before {
  content: "\e96a";
}
.mci-user-reviewed:before {
  content: "\e96b";
}
.mci-user-reviewed-solid:before {
  content: "\e96c";
}
.mci-user-secure:before {
  content: "\e96d";
}
.mci-user-secure-solid:before {
  content: "\e96e";
}
.mci-user-setting:before {
  content: "\e96f";
}
.mci-user-setting-solid:before {
  content: "\e970";
}
.mci-user-single:before {
  content: "\e971";
}
.mci-user-single-solid:before {
  content: "\e972";
}
.mci-user-unlock:before {
  content: "\e973";
}
.mci-user-unlock-solid:before {
  content: "\e974";
}
.mci-user-virus:before {
  content: "\e975";
}
.mci-twitter:before {
  content: "\e976";
}
.mci-table:before {
  content: "\e977";
}
.mci-table-column:before {
  content: "\e978";
}
.mci-table-column-solid:before {
  content: "\e979";
}
.mci-table-row:before {
  content: "\e97a";
}
.mci-table-row-solid:before {
  content: "\e97b";
}
.mci-table-solid:before {
  content: "\e97c";
}
.mci-tag:before {
  content: "\e97d";
}
.mci-tag-filter:before {
  content: "\e97e";
}
.mci-tag-filter-solid:before {
  content: "\e97f";
}
.mci-tag-lock:before {
  content: "\e980";
}
.mci-tag-lock-solid:before {
  content: "\e981";
}
.mci-tag-solid:before {
  content: "\e982";
}
.mci-tag-unlock:before {
  content: "\e983";
}
.mci-tag-unlock-solid:before {
  content: "\e984";
}
.mci-target:before {
  content: "\e985";
}
.mci-target-solid:before {
  content: "\e986";
}
.mci-temperature:before {
  content: "\e987";
}
.mci-temperature-solid:before {
  content: "\e988";
}
.mci-text-bold:before {
  content: "\e989";
}
.mci-text-bold-solid:before {
  content: "\e98a";
}
.mci-text-center:before {
  content: "\e98b";
}
.mci-text-center-solid:before {
  content: "\e98c";
}
.mci-text-color:before {
  content: "\e98d";
}
.mci-text-color-solid:before {
  content: "\e98e";
}
.mci-text-italic:before {
  content: "\e98f";
}
.mci-text-italic-solid:before {
  content: "\e990";
}
.mci-text-justify:before {
  content: "\e991";
}
.mci-text-justify-solid:before {
  content: "\e992";
}
.mci-text-left:before {
  content: "\e993";
}
.mci-text-left-solid:before {
  content: "\e994";
}
.mci-text-right:before {
  content: "\e995";
}
.mci-text-right-solid:before {
  content: "\e996";
}
.mci-text-size:before {
  content: "\e997";
}
.mci-text-size-solid:before {
  content: "\e998";
}
.mci-text-strikeout:before {
  content: "\e999";
}
.mci-text-strikeout-solid:before {
  content: "\e99a";
}
.mci-text-style:before {
  content: "\e99b";
}
.mci-text-style-solid:before {
  content: "\e99c";
}
.mci-text-underline:before {
  content: "\e99d";
}
.mci-text-underline-solid:before {
  content: "\e99e";
}
.mci-thumbs-down:before {
  content: "\e99f";
}
.mci-thumbs-down-solid:before {
  content: "\e9a0";
}
.mci-thumbs-up:before {
  content: "\e9a1";
}
.mci-thumbs-up-solid:before {
  content: "\e9a2";
}
.mci-time:before {
  content: "\e9a3";
}
.mci-time-alarm:before {
  content: "\e9a4";
}
.mci-time-alarm-add:before {
  content: "\e9a5";
}
.mci-time-alarm-add-solid:before {
  content: "\e9a6";
}
.mci-time-alarm-off:before {
  content: "\e9a7";
}
.mci-time-alarm-off-solid:before {
  content: "\e9a8";
}
.mci-time-alarm-on:before {
  content: "\e9a9";
}
.mci-time-alarm-on-solid:before {
  content: "\e9aa";
}
.mci-time-alarm-snooze:before {
  content: "\e9ab";
}
.mci-time-alarm-snooze-solid:before {
  content: "\e9ac";
}
.mci-time-alarm-solid:before {
  content: "\e9ad";
}
.mci-time-history:before {
  content: "\e9ae";
}
.mci-time-history-solid:before {
  content: "\e9af";
}
.mci-time-lock:before {
  content: "\e9b0";
}
.mci-time-lock-solid:before {
  content: "\e9b1";
}
.mci-timer:before {
  content: "\e9b2";
}
.mci-timer-solid:before {
  content: "\e9b3";
}
.mci-time-solid:before {
  content: "\e9b4";
}
.mci-time-stopwatch:before {
  content: "\e9b5";
}
.mci-time-stopwatch-solid:before {
  content: "\e9b6";
}
.mci-time-unlock:before {
  content: "\e9b7";
}
.mci-time-unlock-solid:before {
  content: "\e9b8";
}
.mci-time-update:before {
  content: "\e9b9";
}
.mci-time-update-solid:before {
  content: "\e9ba";
}
.mci-tissue:before {
  content: "\e9bb";
}
.mci-tissue-solid:before {
  content: "\e9bc";
}
.mci-tools:before {
  content: "\e9bd";
}
.mci-tools-solid:before {
  content: "\e9be";
}
.mci-transfer:before {
  content: "\e9bf";
}
.mci-transfer-solid:before {
  content: "\e9c0";
}
.mci-translate:before {
  content: "\e9c1";
}
.mci-translate-solid:before {
  content: "\e9c2";
}
.mci-trash:before {
  content: "\e9c3";
}
.mci-trash-disable:before {
  content: "\e9c4";
}
.mci-trash-disable-solid:before {
  content: "\e9c5";
}
.mci-trash-solid:before {
  content: "\e9c6";
}
.mci-tree-structure:before {
  content: "\e9c7";
}
.mci-tree-structure-solid:before {
  content: "\e9c8";
}
.mci-tumblr:before {
  content: "\e9c9";
}
.mci-tv:before {
  content: "\e9ca";
}
.mci-tv-solid:before {
  content: "\e9cb";
}
.mci-syringe-solid:before {
  content: "\e9cc";
}
.mci-save:before {
  content: "\e9cd";
}
.mci-save-solid:before {
  content: "\e9ce";
}
.mci-scale-expand:before {
  content: "\e9cf";
}
.mci-scale-expand-solid:before {
  content: "\e9d0";
}
.mci-scale-minimize:before {
  content: "\e9d1";
}
.mci-scale-minimize-solid:before {
  content: "\e9d2";
}
.mci-scan:before {
  content: "\e9d3";
}
.mci-scan-solid:before {
  content: "\e9d4";
}
.mci-scissors:before {
  content: "\e9d5";
}
.mci-scissors-solid:before {
  content: "\e9d6";
}
.mci-screwdriver:before {
  content: "\e9d7";
}
.mci-search:before {
  content: "\e9d8";
}
.mci-search-job:before {
  content: "\e9d9";
}
.mci-search-job-solid:before {
  content: "\e9da";
}
.mci-search-money:before {
  content: "\e9db";
}
.mci-search-money-solid:before {
  content: "\e9dc";
}
.mci-search-solid:before {
  content: "\e9dd";
}
.mci-secret-question:before {
  content: "\e9de";
}
.mci-secret-question-solid:before {
  content: "\e9df";
}
.mci-security-priority:before {
  content: "\e9e0";
}
.mci-security-priority-solid:before {
  content: "\e9e1";
}
.mci-security-shield:before {
  content: "\e9e2";
}
.mci-security-shield-solid:before {
  content: "\e9e3";
}
.mci-security-verified:before {
  content: "\e9e4";
}
.mci-security-verified-solid:before {
  content: "\e9e5";
}
.mci-send:before {
  content: "\e9e6";
}
.mci-send-solid:before {
  content: "\e9e7";
}
.mci-service-error:before {
  content: "\e9e8";
}
.mci-service-error-solid:before {
  content: "\e9e9";
}
.mci-service-setting:before {
  content: "\e9ea";
}
.mci-service-setting-solid:before {
  content: "\e9eb";
}
.mci-setting:before {
  content: "\e9ec";
}
.mci-setting-solid:before {
  content: "\e9ed";
}
.mci-share:before {
  content: "\e9ee";
}
.mci-share-arrow:before {
  content: "\e9ef";
}
.mci-share-arrow-solid:before {
  content: "\e9f0";
}
.mci-share-solid:before {
  content: "\e9f1";
}
.mci-shuffle:before {
  content: "\e9f2";
}
.mci-shuffle-data-model:before {
  content: "\e9f3";
}
.mci-shuffle-data-model-solid:before {
  content: "\e9f4";
}
.mci-shuffle-solid:before {
  content: "\e9f5";
}
.mci-sink:before {
  content: "\e9f6";
}
.mci-sink-solid:before {
  content: "\e9f7";
}
.mci-sketch:before {
  content: "\e9f8";
}
.mci-skip:before {
  content: "\e9f9";
}
.mci-skip-next:before {
  content: "\e9fa";
}
.mci-skip-next-circle:before {
  content: "\e9fb";
}
.mci-skip-next-circle-solid:before {
  content: "\e9fc";
}
.mci-skip-next-solid:before {
  content: "\e9fd";
}
.mci-skip-previous:before {
  content: "\e9fe";
}
.mci-skip-previous-circle:before {
  content: "\e9ff";
}
.mci-skip-previous-circle-solid:before {
  content: "\ea00";
}
.mci-skip-previous-solid:before {
  content: "\ea01";
}
.mci-skip-solid:before {
  content: "\ea02";
}
.mci-skull:before {
  content: "\ea03";
}
.mci-skull-solid:before {
  content: "\ea04";
}
.mci-slack:before {
  content: "\ea05";
}
.mci-snapchat:before {
  content: "\ea06";
}
.mci-snowflake:before {
  content: "\ea07";
}
.mci-snowflake-solid:before {
  content: "\ea08";
}
.mci-soap:before {
  content: "\ea09";
}
.mci-soap-solid:before {
  content: "\ea0a";
}
.mci-specs:before {
  content: "\ea0b";
}
.mci-specs-solid:before {
  content: "\ea0c";
}
.mci-split:before {
  content: "\ea0d";
}
.mci-split-solid:before {
  content: "\ea0e";
}
.mci-splunk:before {
  content: "\ea0f";
}
.mci-sports-baseball:before {
  content: "\ea10";
}
.mci-sports-baseball-solid:before {
  content: "\ea11";
}
.mci-sports-basketball:before {
  content: "\ea12";
}
.mci-sports-basketball-solid:before {
  content: "\ea13";
}
.mci-sports-boxing:before {
  content: "\ea14";
}
.mci-sports-boxing-solid:before {
  content: "\ea15";
}
.mci-sports-cricket:before {
  content: "\ea16";
}
.mci-sports-cricket-solid:before {
  content: "\ea17";
}
.mci-sports-football:before {
  content: "\ea18";
}
.mci-sports-football-solid:before {
  content: "\ea19";
}
.mci-sports-golf:before {
  content: "\ea1a";
}
.mci-sports-golf-solid:before {
  content: "\ea1b";
}
.mci-sports-hockey:before {
  content: "\ea1c";
}
.mci-sports-hockey-solid:before {
  content: "\ea1d";
}
.mci-sports-mma:before {
  content: "\ea1e";
}
.mci-sports-mma-solid:before {
  content: "\ea1f";
}
.mci-sports-soccer:before {
  content: "\ea20";
}
.mci-sports-soccer-solid:before {
  content: "\ea21";
}
.mci-sports-tennis:before {
  content: "\ea22";
}
.mci-sports-tennis-solid:before {
  content: "\ea23";
}
.mci-sports-volleyball:before {
  content: "\ea24";
}
.mci-sports-volleyball-solid:before {
  content: "\ea25";
}
.mci-spring:before {
  content: "\ea26";
}
.mci-spring-solid:before {
  content: "\ea27";
}
.mci-star:before {
  content: "\ea28";
}
.mci-star-solid:before {
  content: "\ea29";
}
.mci-stethoscope:before {
  content: "\ea2a";
}
.mci-stethoscope-solid:before {
  content: "\ea2b";
}
.mci-stop-circle:before {
  content: "\ea2c";
}
.mci-stop-circle-solid:before {
  content: "\ea2d";
}
.mci-stop-urgent:before {
  content: "\ea2e";
}
.mci-stop-urgent-solid:before {
  content: "\ea2f";
}
.mci-stop-warning:before {
  content: "\ea30";
}
.mci-stop-warning-solid:before {
  content: "\ea31";
}
.mci-store:before {
  content: "\ea32";
}
.mci-store-close:before {
  content: "\ea33";
}
.mci-store-close-solid:before {
  content: "\ea34";
}
.mci-store-solid:before {
  content: "\ea35";
}
.mci-subdirectory-left:before {
  content: "\ea36";
}
.mci-subdirectory-left-solid:before {
  content: "\ea37";
}
.mci-subdirectory-right:before {
  content: "\ea38";
}
.mci-subdirectory-right-solid:before {
  content: "\ea39";
}
.mci-subtitles:before {
  content: "\ea3a";
}
.mci-subtitles-solid:before {
  content: "\ea3b";
}
.mci-sun:before {
  content: "\ea3c";
}
.mci-sun-solid:before {
  content: "\ea3d";
}
.mci-swap-horizontal:before {
  content: "\ea3e";
}
.mci-swap-horizontal-circle:before {
  content: "\ea3f";
}
.mci-swap-horizontal-circle-solid:before {
  content: "\ea40";
}
.mci-swap-horizontal-solid:before {
  content: "\ea41";
}
.mci-swap-vertical:before {
  content: "\ea42";
}
.mci-swap-vertical-circle:before {
  content: "\ea43";
}
.mci-swap-vertical-circle-solid:before {
  content: "\ea44";
}
.mci-swap-vertical-solid:before {
  content: "\ea45";
}
.mci-sync:before {
  content: "\ea46";
}
.mci-sync-error:before {
  content: "\ea47";
}
.mci-sync-error-solid:before {
  content: "\ea48";
}
.mci-sync-solid:before {
  content: "\ea49";
}
.mci-syringe:before {
  content: "\ea4a";
}
.mci-rss-feed:before {
  content: "\ea4b";
}
.mci-rss-feed-solid:before {
  content: "\ea4c";
}
.mci-radar:before {
  content: "\ea4d";
}
.mci-react:before {
  content: "\ea4e";
}
.mci-rearrange:before {
  content: "\ea4f";
}
.mci-rearrange-solid:before {
  content: "\ea50";
}
.mci-recovery-plus:before {
  content: "\ea51";
}
.mci-recovery-plus-solid:before {
  content: "\ea52";
}
.mci-recovery-shield:before {
  content: "\ea53";
}
.mci-recovery-shield-solid:before {
  content: "\ea54";
}
.mci-recycle:before {
  content: "\ea55";
}
.mci-recycle-solid:before {
  content: "\ea56";
}
.mci-refresh:before {
  content: "\ea57";
}
.mci-refresh-setting:before {
  content: "\ea58";
}
.mci-refresh-setting-solid:before {
  content: "\ea59";
}
.mci-refresh-solid:before {
  content: "\ea5a";
}
.mci-reroute:before {
  content: "\ea5b";
}
.mci-reroute-solid:before {
  content: "\ea5c";
}
.mci-rewards:before {
  content: "\ea5d";
}
.mci-rewards-solid:before {
  content: "\ea5e";
}
.mci-rewind-circle:before {
  content: "\ea5f";
}
.mci-rewind-circle-solid:before {
  content: "\ea60";
}
.mci-rotate-left-solid:before {
  content: "\ea61";
}
.mci-rotate-right:before {
  content: "\ea62";
}
.mci-rotate-right-solid:before {
  content: "\ea63";
}
.mci-question-mark-solid:before {
  content: "\ea64";
}
.mci-queue:before {
  content: "\ea65";
}
.mci-queue-solid:before {
  content: "\ea66";
}
.mci-qr-code:before {
  content: "\ea67";
}
.mci-qr-code-solid:before {
  content: "\ea68";
}
.mci-question-mark:before {
  content: "\ea69";
}
.mci-printer:before {
  content: "\ea6a";
}
.mci-printer-solid:before {
  content: "\ea6b";
}
.mci-processing:before {
  content: "\ea6c";
}
.mci-processing-solid:before {
  content: "\ea6d";
}
.mci-page-first:before {
  content: "\ea6e";
}
.mci-page-first-solid:before {
  content: "\ea6f";
}
.mci-page-last:before {
  content: "\ea70";
}
.mci-page-last-solid:before {
  content: "\ea71";
}
.mci-page-top:before {
  content: "\ea72";
}
.mci-page-top-solid:before {
  content: "\ea73";
}
.mci-paint-bucket:before {
  content: "\ea74";
}
.mci-paint-bucket-solid:before {
  content: "\ea75";
}
.mci-palette:before {
  content: "\ea76";
}
.mci-palette-solid:before {
  content: "\ea77";
}
.mci-partners:before {
  content: "\ea78";
}
.mci-partners-solid:before {
  content: "\ea79";
}
.mci-password-field:before {
  content: "\ea7a";
}
.mci-password-field-solid:before {
  content: "\ea7b";
}
.mci-password-lock:before {
  content: "\ea7c";
}
.mci-password-lock-solid:before {
  content: "\ea7d";
}
.mci-pause-circle:before {
  content: "\ea7e";
}
.mci-pause-circle-solid:before {
  content: "\ea7f";
}
.mci-pencil:before {
  content: "\ea80";
}
.mci-pencil-solid:before {
  content: "\ea81";
}
.mci-pen-tool:before {
  content: "\ea82";
}
.mci-pen-tool-solid:before {
  content: "\ea83";
}
.mci-people-distance:before {
  content: "\ea84";
}
.mci-people-distance-solid:before {
  content: "\ea85";
}
.mci-pets:before {
  content: "\ea86";
}
.mci-pets-solid:before {
  content: "\ea87";
}
.mci-photo:before {
  content: "\ea88";
}
.mci-photo-solid:before {
  content: "\ea89";
}
.mci-picture-in-picture-bottom:before {
  content: "\ea8a";
}
.mci-picture-in-picture-bottom-solid:before {
  content: "\ea8b";
}
.mci-picture-in-picture-top:before {
  content: "\ea8c";
}
.mci-picture-in-picture-top-solid:before {
  content: "\ea8d";
}
.mci-pin:before {
  content: "\ea8e";
}
.mci-pin-solid:before {
  content: "\ea8f";
}
.mci-pinterest:before {
  content: "\ea90";
}
.mci-pipeline:before {
  content: "\ea91";
}
.mci-pipeline-solid:before {
  content: "\ea92";
}
.mci-play-circle:before {
  content: "\ea93";
}
.mci-play-circle-solid:before {
  content: "\ea94";
}
.mci-plug-connect:before {
  content: "\ea95";
}
.mci-plug-connect-solid:before {
  content: "\ea96";
}
.mci-plug-in:before {
  content: "\ea97";
}
.mci-plug-in-solid:before {
  content: "\ea98";
}
.mci-plug-outlet:before {
  content: "\ea99";
}
.mci-plug-outlet-solid:before {
  content: "\ea9a";
}
.mci-plug-power:before {
  content: "\ea9b";
}
.mci-plug-power-solid:before {
  content: "\ea9c";
}
.mci-podcast:before {
  content: "\ea9d";
}
.mci-podcast-solid:before {
  content: "\ea9e";
}
.mci-power:before {
  content: "\ea9f";
}
.mci-power-solid:before {
  content: "\eaa0";
}
.mci-principles:before {
  content: "\eaa1";
}
.mci-principles-solid:before {
  content: "\eaa2";
}
.mci-openstack:before {
  content: "\eaa3";
}
.mci-opsgenie:before {
  content: "\eaa4";
}
.mci-output:before {
  content: "\eaa5";
}
.mci-output-solid:before {
  content: "\eaa6";
}
.mci-okta:before {
  content: "\eaa7";
}
.mci-onboard:before {
  content: "\eaa8";
}
.mci-onboard-solid:before {
  content: "\eaa9";
}
.mci-open-caption:before {
  content: "\eaaa";
}
.mci-open-caption-solid:before {
  content: "\eaab";
}
.mci-open-data-network:before {
  content: "\eaac";
}
.mci-open-data-network-solid:before {
  content: "\eaad";
}
.mci-notification-snooze:before {
  content: "\eaae";
}
.mci-notification-snooze-solid:before {
  content: "\eaaf";
}
.mci-notification-solid:before {
  content: "\eab0";
}
.mci-npm:before {
  content: "\eab1";
}
.mci-network:before {
  content: "\eab2";
}
.mci-network-base:before {
  content: "\eab3";
}
.mci-network-base-solid:before {
  content: "\eab4";
}
.mci-network-mapping:before {
  content: "\eab5";
}
.mci-network-mapping-solid:before {
  content: "\eab6";
}
.mci-network-platform:before {
  content: "\eab7";
}
.mci-network-platform-solid:before {
  content: "\eab8";
}
.mci-network-policy:before {
  content: "\eab9";
}
.mci-network-policy-solid:before {
  content: "\eaba";
}
.mci-network-resource:before {
  content: "\eabb";
}
.mci-network-resource-solid:before {
  content: "\eabc";
}
.mci-network-role:before {
  content: "\eabd";
}
.mci-network-role-solid:before {
  content: "\eabe";
}
.mci-network-solid:before {
  content: "\eabf";
}
.mci-network-workload:before {
  content: "\eac0";
}
.mci-network-workload-group:before {
  content: "\eac1";
}
.mci-network-workload-group-solid:before {
  content: "\eac2";
}
.mci-network-workload-solid:before {
  content: "\eac3";
}
.mci-new-rectangle:before {
  content: "\eac4";
}
.mci-new-rectangle-solid:before {
  content: "\eac5";
}
.mci-news:before {
  content: "\eac6";
}
.mci-newspaper:before {
  content: "\eac7";
}
.mci-newspaper-solid:before {
  content: "\eac8";
}
.mci-news-solid:before {
  content: "\eac9";
}
.mci-new-star:before {
  content: "\eaca";
}
.mci-new-star-solid:before {
  content: "\eacb";
}
.mci-node:before {
  content: "\eacc";
}
.mci-node-js:before {
  content: "\eacd";
}
.mci-nodes:before {
  content: "\eace";
}
.mci-nodes-group:before {
  content: "\eacf";
}
.mci-nodes-group-solid:before {
  content: "\ead0";
}
.mci-node-solid:before {
  content: "\ead1";
}
.mci-nodes-solid:before {
  content: "\ead2";
}
.mci-notebook:before {
  content: "\ead3";
}
.mci-notebook-solid:before {
  content: "\ead4";
}
.mci-notification:before {
  content: "\ead5";
}
.mci-notification-add:before {
  content: "\ead6";
}
.mci-notification-add-solid:before {
  content: "\ead7";
}
.mci-notification-disable:before {
  content: "\ead8";
}
.mci-notification-disable-solid:before {
  content: "\ead9";
}
.mci-notification-minus:before {
  content: "\eada";
}
.mci-notification-minus-solid:before {
  content: "\eadb";
}
.mci-notification-on:before {
  content: "\eadc";
}
.mci-notification-on-solid:before {
  content: "\eadd";
}
.mci-notification-priority:before {
  content: "\eade";
}
.mci-notification-priority-solid:before {
  content: "\eadf";
}
.mci-more-vertical:before {
  content: "\eae0";
}
.mci-more-vertical-circle:before {
  content: "\eae1";
}
.mci-more-vertical-circle-solid:before {
  content: "\eae2";
}
.mci-more-vertical-solid:before {
  content: "\eae3";
}
.mci-machine-learning:before {
  content: "\eae4";
}
.mci-machine-learning-solid:before {
  content: "\eae5";
}
.mci-magic-wand:before {
  content: "\eae6";
}
.mci-magic-wand-solid:before {
  content: "\eae7";
}
.mci-mail:before {
  content: "\eae8";
}
.mci-mail-new:before {
  content: "\eae9";
}
.mci-mail-new-solid:before {
  content: "\eaea";
}
.mci-mail-solid:before {
  content: "\eaeb";
}
.mci-mail-spam:before {
  content: "\eaec";
}
.mci-mail-spam-solid:before {
  content: "\eaed";
}
.mci-map:before {
  content: "\eaee";
}
.mci-map-solid:before {
  content: "\eaef";
}
.mci-mask-ventilator:before {
  content: "\eaf0";
}
.mci-mask-ventilator-solid:before {
  content: "\eaf1";
}
.mci-medal:before {
  content: "\eaf2";
}
.mci-medal-solid:before {
  content: "\eaf3";
}
.mci-medical-clinic:before {
  content: "\eaf4";
}
.mci-medical-clinic-solid:before {
  content: "\eaf5";
}
.mci-medium:before {
  content: "\eaf6";
}
.mci-megaphone:before {
  content: "\eaf7";
}
.mci-megaphone-solid:before {
  content: "\eaf8";
}
.mci-memory-card:before {
  content: "\eaf9";
}
.mci-memory-card-solid:before {
  content: "\eafa";
}
.mci-memory-chip:before {
  content: "\eafb";
}
.mci-memory-chip-solid:before {
  content: "\eafc";
}
.mci-men:before {
  content: "\eafd";
}
.mci-men-solid:before {
  content: "\eafe";
}
.mci-menu-dialpad:before {
  content: "\eaff";
}
.mci-menu-dialpad-solid:before {
  content: "\eb00";
}
.mci-menu-hamburger:before {
  content: "\eb01";
}
.mci-menu-hamburger-solid:before {
  content: "\eb02";
}
.mci-merge:before {
  content: "\eb03";
}
.mci-merge-solid:before {
  content: "\eb04";
}
.mci-mic:before {
  content: "\eb05";
}
.mci-microscope:before {
  content: "\eb06";
}
.mci-microscope-solid:before {
  content: "\eb07";
}
.mci-mic-solid:before {
  content: "\eb08";
}
.mci-minus:before {
  content: "\eb09";
}
.mci-minus-circle:before {
  content: "\eb0a";
}
.mci-minus-circle-solid:before {
  content: "\eb0b";
}
.mci-minus-solid:before {
  content: "\eb0c";
}
.mci-minus-square:before {
  content: "\eb0d";
}
.mci-minus-square-solid:before {
  content: "\eb0e";
}
.mci-monitor:before {
  content: "\eb0f";
}
.mci-monitoring-health:before {
  content: "\eb10";
}
.mci-monitoring-health-solid:before {
  content: "\eb11";
}
.mci-monitor-solid:before {
  content: "\eb12";
}
.mci-moon:before {
  content: "\eb13";
}
.mci-moon-solid:before {
  content: "\eb14";
}
.mci-more-horizontal:before {
  content: "\eb15";
}
.mci-more-horizontal-circle:before {
  content: "\eb16";
}
.mci-more-horizontal-circle-solid:before {
  content: "\eb17";
}
.mci-more-horizontal-solid:before {
  content: "\eb18";
}
.mci-lungs:before {
  content: "\eb19";
}
.mci-lungs-solid:before {
  content: "\eb1a";
}
.mci-lungs-virus:before {
  content: "\eb1b";
}
.mci-lungs-virus-solid:before {
  content: "\eb1c";
}
.mci-label:before {
  content: "\eb1d";
}
.mci-label-solid:before {
  content: "\eb1e";
}
.mci-laptop-in-house:before {
  content: "\eb1f";
}
.mci-laptop-in-house-solid:before {
  content: "\eb20";
}
.mci-law-building:before {
  content: "\eb21";
}
.mci-law-building-solid:before {
  content: "\eb22";
}
.mci-law-scale:before {
  content: "\eb23";
}
.mci-law-scale-solid:before {
  content: "\eb24";
}
.mci-layers:before {
  content: "\eb25";
}
.mci-layers-solid:before {
  content: "\eb26";
}
.mci-layout-one:before {
  content: "\eb27";
}
.mci-layout-one-solid:before {
  content: "\eb28";
}
.mci-layout-three:before {
  content: "\eb29";
}
.mci-layout-three-solid:before {
  content: "\eb2a";
}
.mci-layout-two:before {
  content: "\eb2b";
}
.mci-layout-two-solid:before {
  content: "\eb2c";
}
.mci-leaf:before {
  content: "\eb2d";
}
.mci-leaf-solid:before {
  content: "\eb2e";
}
.mci-lightbulb:before {
  content: "\eb2f";
}
.mci-lightbulb-solid:before {
  content: "\eb30";
}
.mci-line-style:before {
  content: "\eb31";
}
.mci-line-style-solid:before {
  content: "\eb32";
}
.mci-line-thickness:before {
  content: "\eb33";
}
.mci-line-thickness-solid:before {
  content: "\eb34";
}
.mci-line-wrapping:before {
  content: "\eb35";
}
.mci-line-wrapping-solid:before {
  content: "\eb36";
}
.mci-link:before {
  content: "\eb37";
}
.mci-linkedin:before {
  content: "\eb38";
}
.mci-link-solid:before {
  content: "\eb39";
}
.mci-list:before {
  content: "\eb3a";
}
.mci-list-check:before {
  content: "\eb3b";
}
.mci-list-check-solid:before {
  content: "\eb3c";
}
.mci-list-solid:before {
  content: "\eb3d";
}
.mci-location:before {
  content: "\eb3e";
}
.mci-location-add:before {
  content: "\eb3f";
}
.mci-location-add-solid:before {
  content: "\eb40";
}
.mci-location-current:before {
  content: "\eb41";
}
.mci-location-current-solid:before {
  content: "\eb42";
}
.mci-location-edit:before {
  content: "\eb43";
}
.mci-location-edit-solid:before {
  content: "\eb44";
}
.mci-location-not-found:before {
  content: "\eb45";
}
.mci-location-not-found-solid:before {
  content: "\eb46";
}
.mci-location-pin:before {
  content: "\eb47";
}
.mci-location-pin-solid:before {
  content: "\eb48";
}
.mci-location-point-of-interest:before {
  content: "\eb49";
}
.mci-location-point-of-interest-solid:before {
  content: "\eb4a";
}
.mci-location-remove:before {
  content: "\eb4b";
}
.mci-location-remove-solid:before {
  content: "\eb4c";
}
.mci-location-solid:before {
  content: "\eb4d";
}
.mci-lock-close:before {
  content: "\eb4e";
}
.mci-lock-close-solid:before {
  content: "\eb4f";
}
.mci-lock-open:before {
  content: "\eb50";
}
.mci-lock-open-solid:before {
  content: "\eb51";
}
.mci-lock-open-source:before {
  content: "\eb52";
}
.mci-lock-open-source-solid:before {
  content: "\eb53";
}
.mci-keyboard-tab:before {
  content: "\eb54";
}
.mci-keyboard-tab-solid:before {
  content: "\eb55";
}
.mci-key-circle:before {
  content: "\eb56";
}
.mci-key-circle-solid:before {
  content: "\eb57";
}
.mci-key-solid:before {
  content: "\eb58";
}
.mci-key:before {
  content: "\eb59";
}
.mci-keyboard:before {
  content: "\eb5a";
}
.mci-keyboard-backspace:before {
  content: "\eb5b";
}
.mci-keyboard-backspace-solid:before {
  content: "\eb5c";
}
.mci-keyboard-capslock:before {
  content: "\eb5d";
}
.mci-keyboard-capslock-solid:before {
  content: "\eb5e";
}
.mci-keyboard-command:before {
  content: "\eb5f";
}
.mci-keyboard-command-solid:before {
  content: "\eb60";
}
.mci-keyboard-control:before {
  content: "\eb61";
}
.mci-keyboard-control-solid:before {
  content: "\eb62";
}
.mci-keyboard-option:before {
  content: "\eb63";
}
.mci-keyboard-option-solid:before {
  content: "\eb64";
}
.mci-keyboard-percent:before {
  content: "\eb65";
}
.mci-keyboard-percent-solid:before {
  content: "\eb66";
}
.mci-keyboard-return:before {
  content: "\eb67";
}
.mci-keyboard-return-solid:before {
  content: "\eb68";
}
.mci-keyboard-shift:before {
  content: "\eb69";
}
.mci-keyboard-shift-solid:before {
  content: "\eb6a";
}
.mci-keyboard-solid:before {
  content: "\eb6b";
}
.mci-jira:before {
  content: "\eb6c";
}
.mci-jive:before {
  content: "\eb6d";
}
.mci-java:before {
  content: "\eb6e";
}
.mci-jenkins:before {
  content: "\eb6f";
}
.mci-input-solid:before {
  content: "\eb70";
}
.mci-instagram:before {
  content: "\eb71";
}
.mci-invoice:before {
  content: "\eb72";
}
.mci-invoice-solid:before {
  content: "\eb73";
}
.mci-information-circle:before {
  content: "\eb74";
}
.mci-information-circle-solid:before {
  content: "\eb75";
}
.mci-infrastructure:before {
  content: "\eb76";
}
.mci-infrastructure-solid:before {
  content: "\eb77";
}
.mci-input:before {
  content: "\eb78";
}
.mci-hospital:before {
  content: "\eb79";
}
.mci-hospital-solid:before {
  content: "\eb7a";
}
.mci-hospital-symbol:before {
  content: "\eb7b";
}
.mci-hospital-symbol-solid:before {
  content: "\eb7c";
}
.mci-hourglass:before {
  content: "\eb7d";
}
.mci-hourglass-solid:before {
  content: "\eb7e";
}
.mci-hacker:before {
  content: "\eb7f";
}
.mci-hacker-solid:before {
  content: "\eb80";
}
.mci-hands-clean:before {
  content: "\eb81";
}
.mci-hands-clean-solid:before {
  content: "\eb82";
}
.mci-handshake:before {
  content: "\eb83";
}
.mci-handshake-slash:before {
  content: "\eb84";
}
.mci-handshake-slash-solid:before {
  content: "\eb85";
}
.mci-handshake-solid:before {
  content: "\eb86";
}
.mci-hands-medical:before {
  content: "\eb87";
}
.mci-hands-medical-solid:before {
  content: "\eb88";
}
.mci-hands-wash:before {
  content: "\eb89";
}
.mci-hands-wash-solid:before {
  content: "\eb8a";
}
.mci-hands-with-water:before {
  content: "\eb8b";
}
.mci-hands-with-water-solid:before {
  content: "\eb8c";
}
.mci-hash:before {
  content: "\eb8d";
}
.mci-hash-solid:before {
  content: "\eb8e";
}
.mci-heart-health:before {
  content: "\eb8f";
}
.mci-heart-health-solid:before {
  content: "\eb90";
}
.mci-heart-like:before {
  content: "\eb91";
}
.mci-heart-like-solid:before {
  content: "\eb92";
}
.mci-help-circle:before {
  content: "\eb93";
}
.mci-help-circle-solid:before {
  content: "\eb94";
}
.mci-hint-i:before {
  content: "\eb95";
}
.mci-hint-i-solid:before {
  content: "\eb96";
}
.mci-home:before {
  content: "\eb97";
}
.mci-home-solid:before {
  content: "\eb98";
}
.mci-horoscope-aquarius:before {
  content: "\eb99";
}
.mci-horoscope-aquarius-solid:before {
  content: "\eb9a";
}
.mci-horoscope-aries:before {
  content: "\eb9b";
}
.mci-horoscope-aries-solid:before {
  content: "\eb9c";
}
.mci-horoscope-cancer:before {
  content: "\eb9d";
}
.mci-horoscope-cancer-solid:before {
  content: "\eb9e";
}
.mci-horoscope-capricorn:before {
  content: "\eb9f";
}
.mci-horoscope-capricorn-solid:before {
  content: "\eba0";
}
.mci-horoscope-gemini:before {
  content: "\eba1";
}
.mci-horoscope-gemini-solid:before {
  content: "\eba2";
}
.mci-horoscope-leo:before {
  content: "\eba3";
}
.mci-horoscope-leo-solid:before {
  content: "\eba4";
}
.mci-horoscope-libra:before {
  content: "\eba5";
}
.mci-horoscope-libra-solid:before {
  content: "\eba6";
}
.mci-horoscope-pisces:before {
  content: "\eba7";
}
.mci-horoscope-pisces-solid:before {
  content: "\eba8";
}
.mci-horoscope-sagittarius:before {
  content: "\eba9";
}
.mci-horoscope-sagittarius-solid:before {
  content: "\ebaa";
}
.mci-horoscope-scorpio:before {
  content: "\ebab";
}
.mci-horoscope-scorpio-solid:before {
  content: "\ebac";
}
.mci-horoscope-taurus:before {
  content: "\ebad";
}
.mci-horoscope-taurus-solid:before {
  content: "\ebae";
}
.mci-horoscope-virgo:before {
  content: "\ebaf";
}
.mci-horoscope-virgo-solid:before {
  content: "\ebb0";
}
.mci-grid-solid:before {
  content: "\ebb1";
}
.mci-group:before {
  content: "\ebb2";
}
.mci-group-solid:before {
  content: "\ebb3";
}
.mci-germs:before {
  content: "\ebb4";
}
.mci-germs-solid:before {
  content: "\ebb5";
}
.mci-gift:before {
  content: "\ebb6";
}
.mci-gift-solid:before {
  content: "\ebb7";
}
.mci-github:before {
  content: "\ebb8";
}
.mci-gitter:before {
  content: "\ebb9";
}
.mci-go-green:before {
  content: "\ebba";
}
.mci-golang:before {
  content: "\ebbb";
}
.mci-google:before {
  content: "\ebbc";
}
.mci-grid:before {
  content: "\ebbd";
}
.mci-folder-open-solid:before {
  content: "\ebbe";
}
.mci-folder-solid:before {
  content: "\ebbf";
}
.mci-footprint:before {
  content: "\ebc0";
}
.mci-footprint-solid:before {
  content: "\ebc1";
}
.mci-forward-circle:before {
  content: "\ebc2";
}
.mci-forward-circle-solid:before {
  content: "\ebc3";
}
.mci-framer:before {
  content: "\ebc4";
}
.mci-functions:before {
  content: "\ebc5";
}
.mci-functions-solid:before {
  content: "\ebc6";
}
.mci-facebook:before {
  content: "\ebc7";
}
.mci-face-id:before {
  content: "\ebc8";
}
.mci-face-id-solid:before {
  content: "\ebc9";
}
.mci-facial-recognition:before {
  content: "\ebca";
}
.mci-facial-recognition-solid:before {
  content: "\ebcb";
}
.mci-faucet:before {
  content: "\ebcc";
}
.mci-faucet-solid:before {
  content: "\ebcd";
}
.mci-figma:before {
  content: "\ebce";
}
.mci-file-add:before {
  content: "\ebcf";
}
.mci-file-add-solid:before {
  content: "\ebd0";
}
.mci-file-edit:before {
  content: "\ebd1";
}
.mci-file-edit-solid:before {
  content: "\ebd2";
}
.mci-file-minus:before {
  content: "\ebd3";
}
.mci-file-minus-solid:before {
  content: "\ebd4";
}
.mci-file-multiple:before {
  content: "\ebd5";
}
.mci-file-multiple-solid:before {
  content: "\ebd6";
}
.mci-file-search:before {
  content: "\ebd7";
}
.mci-file-search-solid:before {
  content: "\ebd8";
}
.mci-file-text:before {
  content: "\ebd9";
}
.mci-file-text-solid:before {
  content: "\ebda";
}
.mci-filter-add:before {
  content: "\ebdb";
}
.mci-filter-add-solid:before {
  content: "\ebdc";
}
.mci-filter-funnel:before {
  content: "\ebdd";
}
.mci-filter-funnel-solid:before {
  content: "\ebde";
}
.mci-filter-horizontal:before {
  content: "\ebdf";
}
.mci-filter-horizontal-solid:before {
  content: "\ebe0";
}
.mci-filter-list:before {
  content: "\ebe1";
}
.mci-filter-list-solid:before {
  content: "\ebe2";
}
.mci-filter-remove:before {
  content: "\ebe3";
}
.mci-filter-remove-solid:before {
  content: "\ebe4";
}
.mci-filter-vertical:before {
  content: "\ebe5";
}
.mci-filter-vertical-solid:before {
  content: "\ebe6";
}
.mci-fingerprint:before {
  content: "\ebe7";
}
.mci-fingerprint-solid:before {
  content: "\ebe8";
}
.mci-first-aid-kit:before {
  content: "\ebe9";
}
.mci-first-aid-kit-solid:before {
  content: "\ebea";
}
.mci-flag:before {
  content: "\ebeb";
}
.mci-flag-racing:before {
  content: "\ebec";
}
.mci-flag-racing-solid:before {
  content: "\ebed";
}
.mci-flag-solid:before {
  content: "\ebee";
}
.mci-flask:before {
  content: "\ebef";
}
.mci-flask-solid:before {
  content: "\ebf0";
}
.mci-flip:before {
  content: "\ebf1";
}
.mci-flip-solid:before {
  content: "\ebf2";
}
.mci-flower:before {
  content: "\ebf3";
}
.mci-flower-solid:before {
  content: "\ebf4";
}
.mci-folder:before {
  content: "\ebf5";
}
.mci-folder-add:before {
  content: "\ebf6";
}
.mci-folder-add-solid:before {
  content: "\ebf7";
}
.mci-folder-bar-chart:before {
  content: "\ebf8";
}
.mci-folder-bar-chart-solid:before {
  content: "\ebf9";
}
.mci-folder-favorite:before {
  content: "\ebfa";
}
.mci-folder-favorite-solid:before {
  content: "\ebfb";
}
.mci-folder-key:before {
  content: "\ebfc";
}
.mci-folder-key-solid:before {
  content: "\ebfd";
}
.mci-folder-merge:before {
  content: "\ebfe";
}
.mci-folder-merge-solid:before {
  content: "\ebff";
}
.mci-folder-minus:before {
  content: "\ec00";
}
.mci-folder-minus-solid:before {
  content: "\ec01";
}
.mci-folder-multiple:before {
  content: "\ec02";
}
.mci-folder-multiple-solid:before {
  content: "\ec03";
}
.mci-folder-open:before {
  content: "\ec04";
}
.mci-eye-open:before {
  content: "\ec05";
}
.mci-eye-open-solid:before {
  content: "\ec06";
}
.mci-eject-circle:before {
  content: "\ec07";
}
.mci-eject-circle-solid:before {
  content: "\ec08";
}
.mci-ember:before {
  content: "\ec09";
}
.mci-ere:before {
  content: "\ec0a";
}
.mci-ere-solid:before {
  content: "\ec0b";
}
.mci-escalate:before {
  content: "\ec0c";
}
.mci-escalate-solid:before {
  content: "\ec0d";
}
.mci-exclamation-point:before {
  content: "\ec0e";
}
.mci-exclamation-point-solid:before {
  content: "\ec0f";
}
.mci-exit-to-app:before {
  content: "\ec10";
}
.mci-exit-to-app-solid:before {
  content: "\ec11";
}
.mci-expand-fullscreen:before {
  content: "\ec12";
}
.mci-expand-fullscreen-solid:before {
  content: "\ec13";
}
.mci-expand-horizontal:before {
  content: "\ec14";
}
.mci-expand-horizontal-solid:before {
  content: "\ec15";
}
.mci-expand-vertical:before {
  content: "\ec16";
}
.mci-expand-vertical-solid:before {
  content: "\ec17";
}
.mci-expire:before {
  content: "\ec18";
}
.mci-expire-solid:before {
  content: "\ec19";
}
.mci-explore:before {
  content: "\ec1a";
}
.mci-explore-solid:before {
  content: "\ec1b";
}
.mci-external:before {
  content: "\ec1c";
}
.mci-external-solid:before {
  content: "\ec1d";
}
.mci-eye-close:before {
  content: "\ec1e";
}
.mci-eye-close-solid:before {
  content: "\ec1f";
}
.mci-eye-drop:before {
  content: "\ec20";
}
.mci-eye-drop-solid:before {
  content: "\ec21";
}
.mci-drag-solid:before {
  content: "\ec22";
}
.mci-dropbox:before {
  content: "\ec23";
}
.mci-duplicate:before {
  content: "\ec24";
}
.mci-duplicate-solid:before {
  content: "\ec25";
}
.mci-dark-mode:before {
  content: "\ec26";
}
.mci-dark-mode-solid:before {
  content: "\ec27";
}
.mci-dashboard:before {
  content: "\ec28";
}
.mci-dashboard-add:before {
  content: "\ec29";
}
.mci-dashboard-add-solid:before {
  content: "\ec2a";
}
.mci-dashboard-remove:before {
  content: "\ec2b";
}
.mci-dashboard-remove-solid:before {
  content: "\ec2c";
}
.mci-dashboard-solid:before {
  content: "\ec2d";
}
.mci-dashboard-tile:before {
  content: "\ec2e";
}
.mci-dashboard-tile-add:before {
  content: "\ec2f";
}
.mci-dashboard-tile-add-solid:before {
  content: "\ec30";
}
.mci-dashboard-tile-remove:before {
  content: "\ec31";
}
.mci-dashboard-tile-remove-solid:before {
  content: "\ec32";
}
.mci-dashboard-tile-setting:before {
  content: "\ec33";
}
.mci-dashboard-tile-setting-solid:before {
  content: "\ec34";
}
.mci-dashboard-tile-solid:before {
  content: "\ec35";
}
.mci-data-science:before {
  content: "\ec36";
}
.mci-data-science-solid:before {
  content: "\ec37";
}
.mci-data-source:before {
  content: "\ec38";
}
.mci-data-source-solid:before {
  content: "\ec39";
}
.mci-data-storage-check:before {
  content: "\ec3a";
}
.mci-data-storage-check-solid:before {
  content: "\ec3b";
}
.mci-data-storage-lock:before {
  content: "\ec3c";
}
.mci-data-storage-lock-solid:before {
  content: "\ec3d";
}
.mci-data-storage-multiple:before {
  content: "\ec3e";
}
.mci-data-storage-multiple-solid:before {
  content: "\ec3f";
}
.mci-data-storage-network:before {
  content: "\ec40";
}
.mci-data-storage-network-solid:before {
  content: "\ec41";
}
.mci-data-storage-setting:before {
  content: "\ec42";
}
.mci-data-storage-setting-solid:before {
  content: "\ec43";
}
.mci-data-storage-single:before {
  content: "\ec44";
}
.mci-data-storage-single-solid:before {
  content: "\ec45";
}
.mci-decline:before {
  content: "\ec46";
}
.mci-decline-solid:before {
  content: "\ec47";
}
.mci-delivery:before {
  content: "\ec48";
}
.mci-delivery-fast:before {
  content: "\ec49";
}
.mci-delivery-fast-solid:before {
  content: "\ec4a";
}
.mci-delivery-schedule:before {
  content: "\ec4b";
}
.mci-delivery-schedule-solid:before {
  content: "\ec4c";
}
.mci-delivery-solid:before {
  content: "\ec4d";
}
.mci-denali:before {
  content: "\ec4e";
}
.mci-descending-bars:before {
  content: "\ec4f";
}
.mci-descending-bars-solid:before {
  content: "\ec50";
}
.mci-descending-z-a:before {
  content: "\ec51";
}
.mci-descending-z-a-solid:before {
  content: "\ec52";
}
.mci-device-mobile:before {
  content: "\ec53";
}
.mci-device-mobile-comment:before {
  content: "\ec54";
}
.mci-device-mobile-comment-solid:before {
  content: "\ec55";
}
.mci-device-mobile-graph:before {
  content: "\ec56";
}
.mci-device-mobile-graph-solid:before {
  content: "\ec57";
}
.mci-device-mobile-notification:before {
  content: "\ec58";
}
.mci-device-mobile-notification-solid:before {
  content: "\ec59";
}
.mci-device-mobile-priority:before {
  content: "\ec5a";
}
.mci-device-mobile-priority-solid:before {
  content: "\ec5b";
}
.mci-device-mobile-solid:before {
  content: "\ec5c";
}
.mci-device-multiple:before {
  content: "\ec5d";
}
.mci-device-multiple-solid:before {
  content: "\ec5e";
}
.mci-device-tablet:before {
  content: "\ec5f";
}
.mci-device-tablet-comment:before {
  content: "\ec60";
}
.mci-device-tablet-comment-solid:before {
  content: "\ec61";
}
.mci-device-tablet-graph:before {
  content: "\ec62";
}
.mci-device-tablet-graph-solid:before {
  content: "\ec63";
}
.mci-device-tablet-notification:before {
  content: "\ec64";
}
.mci-device-tablet-notification-solid:before {
  content: "\ec65";
}
.mci-device-tablet-solid:before {
  content: "\ec66";
}
.mci-dev-ops:before {
  content: "\ec67";
}
.mci-dev-ops-solid:before {
  content: "\ec68";
}
.mci-diamond:before {
  content: "\ec69";
}
.mci-diamond-solid:before {
  content: "\ec6a";
}
.mci-discord:before {
  content: "\ec6b";
}
.mci-dna:before {
  content: "\ec6c";
}
.mci-dna-solid:before {
  content: "\ec6d";
}
.mci-docker:before {
  content: "\ec6e";
}
.mci-domain:before {
  content: "\ec6f";
}
.mci-domain-solid:before {
  content: "\ec70";
}
.mci-download:before {
  content: "\ec71";
}
.mci-download-solid:before {
  content: "\ec72";
}
.mci-drag:before {
  content: "\ec73";
}
.mci-drag-arrow:before {
  content: "\ec74";
}
.mci-drag-arrow-solid:before {
  content: "\ec75";
}
.mci-drag-handle:before {
  content: "\ec76";
}
.mci-drag-handle-solid:before {
  content: "\ec77";
}
.mci-dragonfly:before {
  content: "\ec78";
}
.mci-cursor-pointer:before {
  content: "\ec79";
}
.mci-cursor-pointer-solid:before {
  content: "\ec7a";
}
.mci-calculator:before {
  content: "\ec7b";
}
.mci-calculator-solid:before {
  content: "\ec7c";
}
.mci-calendar:before {
  content: "\ec7d";
}
.mci-calendar-date-range:before {
  content: "\ec7e";
}
.mci-calendar-date-range-solid:before {
  content: "\ec7f";
}
.mci-calendar-event:before {
  content: "\ec80";
}
.mci-calendar-event-activity:before {
  content: "\ec81";
}
.mci-calendar-event-activity-solid:before {
  content: "\ec82";
}
.mci-calendar-event-available:before {
  content: "\ec83";
}
.mci-calendar-event-available-solid:before {
  content: "\ec84";
}
.mci-calendar-event-busy:before {
  content: "\ec85";
}
.mci-calendar-event-busy-solid:before {
  content: "\ec86";
}
.mci-calendar-event-solid:before {
  content: "\ec87";
}
.mci-calendar-solid:before {
  content: "\ec88";
}
.mci-calypso:before {
  content: "\ec89";
}
.mci-camera:before {
  content: "\ec8a";
}
.mci-camera-solid:before {
  content: "\ec8b";
}
.mci-card-american-express:before {
  content: "\ec8c";
}
.mci-card-american-express-solid:before {
  content: "\ec8d";
}
.mci-card-apple-pay:before {
  content: "\ec8e";
}
.mci-card-apple-pay-solid:before {
  content: "\ec8f";
}
.mci-card-mastercard:before {
  content: "\ec90";
}
.mci-card-mastercard-solid:before {
  content: "\ec91";
}
.mci-card-paypal:before {
  content: "\ec92";
}
.mci-card-paypal-solid:before {
  content: "\ec93";
}
.mci-card-visa:before {
  content: "\ec94";
}
.mci-card-visa-solid:before {
  content: "\ec95";
}
.mci-cart-shopping:before {
  content: "\ec96";
}
.mci-cart-shopping-add:before {
  content: "\ec97";
}
.mci-cart-shopping-add-solid:before {
  content: "\ec98";
}
.mci-cart-shopping-remove:before {
  content: "\ec99";
}
.mci-cart-shopping-remove-solid:before {
  content: "\ec9a";
}
.mci-cart-shopping-solid:before {
  content: "\ec9b";
}
.mci-cash-payment:before {
  content: "\ec9c";
}
.mci-cash-payment-solid:before {
  content: "\ec9d";
}
.mci-category:before {
  content: "\ec9e";
}
.mci-category-list:before {
  content: "\ec9f";
}
.mci-category-list-solid:before {
  content: "\eca0";
}
.mci-category-solid:before {
  content: "\eca1";
}
.mci-certificate-check:before {
  content: "\eca2";
}
.mci-certificate-check-solid:before {
  content: "\eca3";
}
.mci-certificate-key:before {
  content: "\eca4";
}
.mci-certificate-key-solid:before {
  content: "\eca5";
}
.mci-certificate-ribbon:before {
  content: "\eca6";
}
.mci-certificate-ribbon-solid:before {
  content: "\eca7";
}
.mci-certificate-ssl:before {
  content: "\eca8";
}
.mci-certificate-ssl-solid:before {
  content: "\eca9";
}
.mci-certificate-star:before {
  content: "\ecaa";
}
.mci-certificate-star-solid:before {
  content: "\ecab";
}
.mci-chart-area:before {
  content: "\ecac";
}
.mci-chart-area-solid:before {
  content: "\ecad";
}
.mci-chart-bar-horizontal:before {
  content: "\ecae";
}
.mci-chart-bar-horizontal-solid:before {
  content: "\ecaf";
}
.mci-chart-bar-vertical:before {
  content: "\ecb0";
}
.mci-chart-bar-vertical-solid:before {
  content: "\ecb1";
}
.mci-chart-big-number:before {
  content: "\ecb2";
}
.mci-chart-big-number-solid:before {
  content: "\ecb3";
}
.mci-chart-create:before {
  content: "\ecb4";
}
.mci-chart-create-solid:before {
  content: "\ecb5";
}
.mci-chart-donut:before {
  content: "\ecb6";
}
.mci-chart-donut-solid:before {
  content: "\ecb7";
}
.mci-chart-downward:before {
  content: "\ecb8";
}
.mci-chart-downward-solid:before {
  content: "\ecb9";
}
.mci-chart-edit:before {
  content: "\ecba";
}
.mci-chart-edit-solid:before {
  content: "\ecbb";
}
.mci-chart-flat:before {
  content: "\ecbc";
}
.mci-chart-flat-solid:before {
  content: "\ecbd";
}
.mci-chart-heatmap:before {
  content: "\ecbe";
}
.mci-chart-heatmap-solid:before {
  content: "\ecbf";
}
.mci-chart-line:before {
  content: "\ecc0";
}
.mci-chart-line-solid:before {
  content: "\ecc1";
}
.mci-chart-line-time:before {
  content: "\ecc2";
}
.mci-chart-line-time-solid:before {
  content: "\ecc3";
}
.mci-chart-metric:before {
  content: "\ecc4";
}
.mci-chart-metric-solid:before {
  content: "\ecc5";
}
.mci-chart-multi-metric:before {
  content: "\ecc6";
}
.mci-chart-multi-metric-solid:before {
  content: "\ecc7";
}
.mci-chart-pie:before {
  content: "\ecc8";
}
.mci-chart-pie-solid:before {
  content: "\ecc9";
}
.mci-chart-scatterplot:before {
  content: "\ecca";
}
.mci-chart-scatterplot-solid:before {
  content: "\eccb";
}
.mci-chart-threshold:before {
  content: "\eccc";
}
.mci-chart-threshold-solid:before {
  content: "\eccd";
}
.mci-chart-upward:before {
  content: "\ecce";
}
.mci-chart-upward-solid:before {
  content: "\eccf";
}
.mci-chart-wave:before {
  content: "\ecd0";
}
.mci-chart-wave-solid:before {
  content: "\ecd1";
}
.mci-chart-x-axis:before {
  content: "\ecd2";
}
.mci-chart-x-axis-solid:before {
  content: "\ecd3";
}
.mci-chart-y-axis:before {
  content: "\ecd4";
}
.mci-chart-y-axis-solid:before {
  content: "\ecd5";
}
.mci-check:before {
  content: "\ecd6";
}
.mci-check-all:before {
  content: "\ecd7";
}
.mci-check-all-solid:before {
  content: "\ecd8";
}
.mci-check-circle:before {
  content: "\ecd9";
}
.mci-check-circle-solid:before {
  content: "\ecda";
}
.mci-check-solid:before {
  content: "\ecdb";
}
.mci-check-square:before {
  content: "\ecdc";
}
.mci-check-square-solid:before {
  content: "\ecdd";
}
.mci-cicd:before {
  content: "\ecde";
}
.mci-cicd-solid:before {
  content: "\ecdf";
}
.mci-circle:before {
  content: "\ece0";
}
.mci-circle-solid:before {
  content: "\ece1";
}
.mci-cisco-anyconnect:before {
  content: "\ece2";
}
.mci-ckms:before {
  content: "\ece3";
}
.mci-close:before {
  content: "\ece4";
}
.mci-close-caption:before {
  content: "\ece5";
}
.mci-close-caption-solid:before {
  content: "\ece6";
}
.mci-close-circle:before {
  content: "\ece7";
}
.mci-close-circle-solid:before {
  content: "\ece8";
}
.mci-close-solid:before {
  content: "\ece9";
}
.mci-close-square:before {
  content: "\ecea";
}
.mci-close-square-solid:before {
  content: "\eceb";
}
.mci-clothes:before {
  content: "\ecec";
}
.mci-clothes-solid:before {
  content: "\eced";
}
.mci-cloud:before {
  content: "\ecee";
}
.mci-cloud-check:before {
  content: "\ecef";
}
.mci-cloud-check-solid:before {
  content: "\ecf0";
}
.mci-cloud-data-network:before {
  content: "\ecf1";
}
.mci-cloud-data-network-solid:before {
  content: "\ecf2";
}
.mci-cloud-disable:before {
  content: "\ecf3";
}
.mci-cloud-disable-solid:before {
  content: "\ecf4";
}
.mci-cloud-download:before {
  content: "\ecf5";
}
.mci-cloud-download-solid:before {
  content: "\ecf6";
}
.mci-cloud-error:before {
  content: "\ecf7";
}
.mci-cloud-error-solid:before {
  content: "\ecf8";
}
.mci-cloud-service:before {
  content: "\ecf9";
}
.mci-cloud-service-solid:before {
  content: "\ecfa";
}
.mci-cloud-settings:before {
  content: "\ecfb";
}
.mci-cloud-settings-solid:before {
  content: "\ecfc";
}
.mci-cloud-solid:before {
  content: "\ecfd";
}
.mci-cloud-upload:before {
  content: "\ecfe";
}
.mci-cloud-upload-solid:before {
  content: "\ecff";
}
.mci-code:before {
  content: "\ed00";
}
.mci-code-programming:before {
  content: "\ed01";
}
.mci-code-programming-solid:before {
  content: "\ed02";
}
.mci-code-solid:before {
  content: "\ed03";
}
.mci-collapse-fullscreen:before {
  content: "\ed04";
}
.mci-collapse-fullscreen-solid:before {
  content: "\ed05";
}
.mci-collapse-horizontal:before {
  content: "\ed06";
}
.mci-collapse-horizontal-solid:before {
  content: "\ed07";
}
.mci-collapse-vertical:before {
  content: "\ed08";
}
.mci-collapse-vertical-solid:before {
  content: "\ed09";
}
.mci-collection:before {
  content: "\ed0a";
}
.mci-collection-solid:before {
  content: "\ed0b";
}
.mci-comment-announcement:before {
  content: "\ed0c";
}
.mci-comment-announcement-solid:before {
  content: "\ed0d";
}
.mci-comment-blank:before {
  content: "\ed0e";
}
.mci-comment-blank-solid:before {
  content: "\ed0f";
}
.mci-comment-forum:before {
  content: "\ed10";
}
.mci-comment-forum-solid:before {
  content: "\ed11";
}
.mci-comment-help:before {
  content: "\ed12";
}
.mci-comment-help-solid:before {
  content: "\ed13";
}
.mci-comment-text:before {
  content: "\ed14";
}
.mci-comment-text-solid:before {
  content: "\ed15";
}
.mci-computer:before {
  content: "\ed16";
}
.mci-computer-code:before {
  content: "\ed17";
}
.mci-computer-code-solid:before {
  content: "\ed18";
}
.mci-computer-secured:before {
  content: "\ed19";
}
.mci-computer-secured-solid:before {
  content: "\ed1a";
}
.mci-computer-setting:before {
  content: "\ed1b";
}
.mci-computer-setting-solid:before {
  content: "\ed1c";
}
.mci-computer-solid:before {
  content: "\ed1d";
}
.mci-contacts:before {
  content: "\ed1e";
}
.mci-contacts-solid:before {
  content: "\ed1f";
}
.mci-container:before {
  content: "\ed20";
}
.mci-container-optimize:before {
  content: "\ed21";
}
.mci-container-optimize-solid:before {
  content: "\ed22";
}
.mci-container-solid:before {
  content: "\ed23";
}
.mci-copy-file:before {
  content: "\ed24";
}
.mci-copy-file-solid:before {
  content: "\ed25";
}
.mci-coronavirus:before {
  content: "\ed26";
}
.mci-coronavirus-solid:before {
  content: "\ed27";
}
.mci-cosmo .path1:before {
  content: "\ed28";
  color: rgb(0, 0, 0);
}
.mci-cosmo .path2:before {
  content: "\ed29";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.mci-cosmo .path3:before {
  content: "\ed2a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.mci-cosmo .path4:before {
  content: "\ed2b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.mci-cosmo .path5:before {
  content: "\ed2c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.mci-cosmo .path6:before {
  content: "\ed2d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.mci-cosmo .path7:before {
  content: "\ed2e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.mci-coupon:before {
  content: "\ed2f";
}
.mci-coupon-solid:before {
  content: "\ed30";
}
.mci-creditcard:before {
  content: "\ed31";
}
.mci-creditcard-solid:before {
  content: "\ed32";
}
.mci-css:before {
  content: "\ed33";
}
.mci-currency-bag-dollar:before {
  content: "\ed34";
}
.mci-currency-bag-dollar-solid:before {
  content: "\ed35";
}
.mci-currency-dollar:before {
  content: "\ed36";
}
.mci-currency-dollar-solid:before {
  content: "\ed37";
}
.mci-currency-euro:before {
  content: "\ed38";
}
.mci-currency-euro-solid:before {
  content: "\ed39";
}
.mci-currency-revenue:before {
  content: "\ed3a";
}
.mci-currency-revenue-solid:before {
  content: "\ed3b";
}
.mci-currency-yen:before {
  content: "\ed3c";
}
.mci-currency-yen-solid:before {
  content: "\ed3d";
}
.mci-cursor-arrow:before {
  content: "\ed3e";
}
.mci-cursor-arrow-solid:before {
  content: "\ed3f";
}
.mci-browser-setting-solid:before {
  content: "\ed40";
}
.mci-browser-solid:before {
  content: "\ed41";
}
.mci-bug:before {
  content: "\ed42";
}
.mci-bug-solid:before {
  content: "\ed43";
}
.mci-baby-stroller:before {
  content: "\ed44";
}
.mci-baby-stroller-solid:before {
  content: "\ed45";
}
.mci-bare-metal:before {
  content: "\ed46";
}
.mci-bare-metal-solid:before {
  content: "\ed47";
}
.mci-battery-charging:before {
  content: "\ed48";
}
.mci-battery-charging-solid:before {
  content: "\ed49";
}
.mci-battery-empty:before {
  content: "\ed4a";
}
.mci-battery-empty-solid:before {
  content: "\ed4b";
}
.mci-battery-full:before {
  content: "\ed4c";
}
.mci-battery-full-solid:before {
  content: "\ed4d";
}
.mci-battery-half:before {
  content: "\ed4e";
}
.mci-battery-half-solid:before {
  content: "\ed4f";
}
.mci-battery-low:before {
  content: "\ed50";
}
.mci-battery-low-solid:before {
  content: "\ed51";
}
.mci-bing:before {
  content: "\ed52";
}
.mci-biohazard:before {
  content: "\ed53";
}
.mci-biohazard-solid:before {
  content: "\ed54";
}
.mci-blood-test:before {
  content: "\ed55";
}
.mci-blood-test-solid:before {
  content: "\ed56";
}
.mci-bomb:before {
  content: "\ed57";
}
.mci-bomb-solid:before {
  content: "\ed58";
}
.mci-book:before {
  content: "\ed59";
}
.mci-bookmark:before {
  content: "\ed5a";
}
.mci-bookmark-multiple:before {
  content: "\ed5b";
}
.mci-bookmark-multiple-solid:before {
  content: "\ed5c";
}
.mci-bookmark-solid:before {
  content: "\ed5d";
}
.mci-book-solid:before {
  content: "\ed5e";
}
.mci-branch:before {
  content: "\ed5f";
}
.mci-branch-solid:before {
  content: "\ed60";
}
.mci-briefcase:before {
  content: "\ed61";
}
.mci-briefcase-solid:before {
  content: "\ed62";
}
.mci-broadcast:before {
  content: "\ed63";
}
.mci-broadcast-solid:before {
  content: "\ed64";
}
.mci-broadcast-tower:before {
  content: "\ed65";
}
.mci-broadcast-tower-solid:before {
  content: "\ed66";
}
.mci-browser:before {
  content: "\ed67";
}
.mci-browser-bar-chart:before {
  content: "\ed68";
}
.mci-browser-bar-chart-solid:before {
  content: "\ed69";
}
.mci-browser-code:before {
  content: "\ed6a";
}
.mci-browser-code-solid:before {
  content: "\ed6b";
}
.mci-browser-cost:before {
  content: "\ed6c";
}
.mci-browser-cost-solid:before {
  content: "\ed6d";
}
.mci-browser-delete:before {
  content: "\ed6e";
}
.mci-browser-delete-solid:before {
  content: "\ed6f";
}
.mci-browser-open-in:before {
  content: "\ed70";
}
.mci-browser-open-in-solid:before {
  content: "\ed71";
}
.mci-browser-setting:before {
  content: "\ed72";
}
.mci-audio-description:before {
  content: "\ed73";
}
.mci-audio-description-solid:before {
  content: "\ed74";
}
.mci-aws:before {
  content: "\ed75";
}
.mci-accessibility:before {
  content: "\ed76";
}
.mci-accessibility-assisted-listening:before {
  content: "\ed77";
}
.mci-accessibility-assisted-listening-solid:before {
  content: "\ed78";
}
.mci-accessibility-blind:before {
  content: "\ed79";
}
.mci-accessibility-blind-solid:before {
  content: "\ed7a";
}
.mci-accessibility-braille:before {
  content: "\ed7b";
}
.mci-accessibility-braille-solid:before {
  content: "\ed7c";
}
.mci-accessibility-circle:before {
  content: "\ed7d";
}
.mci-accessibility-circle-solid:before {
  content: "\ed7e";
}
.mci-accessibility-cognitive-disability:before {
  content: "\ed7f";
}
.mci-accessibility-cognitive-disability-solid:before {
  content: "\ed80";
}
.mci-accessibility-deaf:before {
  content: "\ed81";
}
.mci-accessibility-deaf-solid:before {
  content: "\ed82";
}
.mci-accessibility-hearing:before {
  content: "\ed83";
}
.mci-accessibility-hearing-solid:before {
  content: "\ed84";
}
.mci-accessibility-sign-language:before {
  content: "\ed85";
}
.mci-accessibility-sign-language-solid:before {
  content: "\ed86";
}
.mci-accessibility-solid:before {
  content: "\ed87";
}
.mci-accessible:before {
  content: "\ed88";
}
.mci-accessible-solid:before {
  content: "\ed89";
}
.mci-activity:before {
  content: "\ed8a";
}
.mci-activity-solid:before {
  content: "\ed8b";
}
.mci-add:before {
  content: "\ed8c";
}
.mci-add-circle:before {
  content: "\ed8d";
}
.mci-add-circle-solid:before {
  content: "\ed8e";
}
.mci-add-solid:before {
  content: "\ed8f";
}
.mci-add-square:before {
  content: "\ed90";
}
.mci-add-square-solid:before {
  content: "\ed91";
}
.mci-adobe-xd:before {
  content: "\ed92";
}
.mci-ai:before {
  content: "\ed93";
}
.mci-airplane:before {
  content: "\ed94";
}
.mci-airplane-slash:before {
  content: "\ed95";
}
.mci-airplane-slash-solid:before {
  content: "\ed96";
}
.mci-airplane-solid:before {
  content: "\ed97";
}
.mci-ai-solid:before {
  content: "\ed98";
}
.mci-align-bottom:before {
  content: "\ed99";
}
.mci-align-bottom-solid:before {
  content: "\ed9a";
}
.mci-align-center:before {
  content: "\ed9b";
}
.mci-align-center-solid:before {
  content: "\ed9c";
}
.mci-align-object-bottom:before {
  content: "\ed9d";
}
.mci-align-object-bottom-solid:before {
  content: "\ed9e";
}
.mci-align-object-center:before {
  content: "\ed9f";
}
.mci-align-object-center-solid:before {
  content: "\eda0";
}
.mci-align-object-horizontal:before {
  content: "\eda1";
}
.mci-align-object-horizontal-solid:before {
  content: "\eda2";
}
.mci-align-object-left:before {
  content: "\eda3";
}
.mci-align-object-left-solid:before {
  content: "\eda4";
}
.mci-align-object-right:before {
  content: "\eda5";
}
.mci-align-object-right-solid:before {
  content: "\eda6";
}
.mci-align-object-top:before {
  content: "\eda7";
}
.mci-align-object-top-solid:before {
  content: "\eda8";
}
.mci-align-object-vertical:before {
  content: "\eda9";
}
.mci-align-object-vertical-solid:before {
  content: "\edaa";
}
.mci-align-top:before {
  content: "\edab";
}
.mci-align-top-solid:before {
  content: "\edac";
}
.mci-ambulance:before {
  content: "\edad";
}
.mci-ambulance-solid:before {
  content: "\edae";
}
.mci-android:before {
  content: "\edaf";
}
.mci-angular:before {
  content: "\edb0";
}
.mci-apple:before {
  content: "\edb1";
}
.mci-apple-ios:before {
  content: "\edb2";
}
.mci-apps-large:before {
  content: "\edb3";
}
.mci-apps-large-solid:before {
  content: "\edb4";
}
.mci-apps-small:before {
  content: "\edb5";
}
.mci-apps-small-solid:before {
  content: "\edb6";
}
.mci-archive:before {
  content: "\edb7";
}
.mci-archive-solid:before {
  content: "\edb8";
}
.mci-arrow-back:before {
  content: "\edb9";
}
.mci-arrow-back-solid:before {
  content: "\edba";
}
.mci-arrow-block-down:before {
  content: "\edbb";
}
.mci-arrow-block-down-solid:before {
  content: "\edbc";
}
.mci-arrow-block-left:before {
  content: "\edbd";
}
.mci-arrow-block-left-solid:before {
  content: "\edbe";
}
.mci-arrow-block-right:before {
  content: "\edbf";
}
.mci-arrow-block-right-solid:before {
  content: "\edc0";
}
.mci-arrow-block-up:before {
  content: "\edc1";
}
.mci-arrow-block-up-solid:before {
  content: "\edc2";
}
.mci-arrow-down:before {
  content: "\edc3";
}
.mci-arrow-down-solid:before {
  content: "\edc4";
}
.mci-arrowhead-down:before {
  content: "\edc5";
}
.mci-arrowhead-down-circle:before {
  content: "\edc6";
}
.mci-arrowhead-down-circle-solid:before {
  content: "\edc7";
}
.mci-arrowhead-down-solid:before {
  content: "\edc8";
}
.mci-arrowhead-left:before {
  content: "\edc9";
}
.mci-arrowhead-left-circle:before {
  content: "\edca";
}
.mci-arrowhead-left-circle-solid:before {
  content: "\edcb";
}
.mci-arrowhead-left-solid:before {
  content: "\edcc";
}
.mci-arrowhead-right:before {
  content: "\edcd";
}
.mci-arrowhead-right-circle:before {
  content: "\edce";
}
.mci-arrowhead-right-circle-solid:before {
  content: "\edcf";
}
.mci-arrowhead-right-solid:before {
  content: "\edd0";
}
.mci-arrowhead-up:before {
  content: "\edd1";
}
.mci-arrowhead-up-circle:before {
  content: "\edd2";
}
.mci-arrowhead-up-circle-solid:before {
  content: "\edd3";
}
.mci-arrowhead-up-solid:before {
  content: "\edd4";
}
.mci-arrow-left:before {
  content: "\edd5";
}
.mci-arrow-left-solid:before {
  content: "\edd6";
}
.mci-arrow-right:before {
  content: "\edd7";
}
.mci-arrow-right-solid:before {
  content: "\edd8";
}
.mci-arrow-skip-back:before {
  content: "\edd9";
}
.mci-arrow-skip-back-solid:before {
  content: "\edda";
}
.mci-arrow-skip-forward:before {
  content: "\eddb";
}
.mci-arrow-skip-forward-solid:before {
  content: "\eddc";
}
.mci-arrow-sort:before {
  content: "\eddd";
}
.mci-arrow-sort-solid:before {
  content: "\edde";
}
.mci-arrow-up:before {
  content: "\eddf";
}
.mci-arrow-up-solid:before {
  content: "\ede0";
}
.mci-artifactory:before {
  content: "\ede1";
}
.mci-arts-and-crafts:before {
  content: "\ede2";
}
.mci-arts-and-crafts-solid:before {
  content: "\ede3";
}
.mci-ascending-a-z:before {
  content: "\ede4";
}
.mci-ascending-a-z-solid:before {
  content: "\ede5";
}
.mci-ascending-bars:before {
  content: "\ede6";
}
.mci-ascending-bars-solid:before {
  content: "\ede7";
}
.mci-assignment-check:before {
  content: "\ede8";
}
.mci-assignment-check-solid:before {
  content: "\ede9";
}
.mci-assignment-priority:before {
  content: "\edea";
}
.mci-assignment-priority-solid:before {
  content: "\edeb";
}
.mci-assignment-text:before {
  content: "\edec";
}
.mci-assignment-text-solid:before {
  content: "\eded";
}
.mci-athenz:before {
  content: "\edee";
}
.mci-attachment:before {
  content: "\edef";
}
.mci-attachment-solid:before {
  content: "\edf0";
}
.mci-abstract:before {
  content: "\edf1";
}
.mci-g-solid:before {
  content: "\edf2";
}
.mci-g:before {
  content: "\edf3";
}
