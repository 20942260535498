$xp-000: #111832;
$xp-005: #1d243c;
$xp-010: #293047;
$xp-020: #41465b;
$xp-040: #707484;
$xp-060: #a0a3ad;
$xp-080: #cfd1d6;
$xp-090: #e7e8eb;
$xp-095: #f2f3f4;
$xp-100: #ffffff;
$xp-suc: #76b81f;
$xp-war: #eba90f;
$xp-dan: #be1c1c;
$xp-inf: #3391d6;
$xp-pri: #e69107;
$xp-sec: #e17800;
$xp-gra: linear-gradient(45deg, $xp-pri, $xp-sec);
$t-gra: linear-gradient(45deg, $xp-095, $xp-090);

$t1: #ffffff; /* Tint 1  */
$t2: #f2f3f4; /* Tint 2  */
$t3: #e7e8eb; /* Tint 3  */
$t4: #cfd1d6; /* Tint 4  */
$t5: #a0a3ad; /* Tint 5  */
$t6: #707484; /* Tint 6  */
$t7: #41465b; /* Tint 7  */
$t8: #293047; /* Tint 8  */
$t9: #1d243c; /* Tint 9  */

$banner-height: 31.25rem;
$topbar-height: 5rem;
$body-min-width: 20rem;

$font-family: "Baloo 2", sans-serif;

$typography-modular-html-base: 1rem;
$typography-modular-base: 1rem;
$typography-modular-6: 1rem;
$typography-modular-5: 1rem;
$typography-modular-4: 1.125rem;
$typography-modular-3: 1.5rem;
$typography-modular-2: 2.25rem;
$typography-modular-1: 3.35rem;
$spacing-vertical-base: 22.5px;
$spacing-vertical-base-half: calc($spacing-vertical-base/2);
$spacing-vertical-1: $spacing-vertical-base;
$spacing-vertical-1-5: calc(1.5 * $spacing-vertical-base);
$spacing-vertical-2: calc(2 * $spacing-vertical-base);
$spacing-vertical-3: calc(3 * $spacing-vertical-base);

$px-01: 0.063rem;
// $px-01-5: 0.094rem;
$px-01-5: $px-01;
$px-03: 0.188rem;
$px-10: 0.625rem;
$px-15: 0.938rem;
$px-20: 1.25rem;
$px-30: 1.875rem;
$px-40: 2.5rem;
$px-45: 2.813rem;
$px-80: 5rem;
$px-100: 6.25rem;
$px-300: 18.75rem;

$epg-scale-hour: $px-300;
$epg-scale-minute: calc($epg-scale-hour / 60);
$epg-scale-second: calc($epg-scale-minute / 60);

$standard-spacer: $px-30;
$standard-border-width: $px-01-5;
$standard-border-color: $xp-090;
$standard-border: $standard-border-width solid $standard-border-color;

$standard-border-radius: $px-10;
$smaller-border-radius: calc($px-10 / 2);
$smallest-border-radius: $px-03;

$standard-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
$standard-shadow-002: 0 2px 3px 0 rgba(0, 0, 0, 0.05);

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

$loader-background: radial-gradient($xp-020 25%, $xp-000 100%) center center;
