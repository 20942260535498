div#app-loader,
div#app-loader.refreshing {
  display: none;
  opacity: 0;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: grey;
  background: none;
  transition: all 0.25s ease-in-out;
  transition-behavior: allow-discrete;
  z-index: -1;
}
app-root:empty + div#app-loader,
div#app-loader.refreshing.is-loading {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: radial-gradient(#41465b 25%, #111832 100%) center center;
}
