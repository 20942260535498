@import "variables.scss";

form {
  div.form-control.xperience {
    margin-block-end: 0;
    &.m-block-0 {
      margin-block: 0 0;
    }
    textarea {
      padding-left: 0;
      padding-right: 0;
    }
    input {
      border: $standard-border;
      padding: $px-15 calc($px-15 / 2);
      background-color: $xp-100;
      color: $xp-040;
      border-radius: $px-03;
      outline: none;
      caret-color: $xp-pri;
      &[type="checkbox"] {
        border-radius: $px-03;
        display: grid;
        appearance: none;
        -webkit-appearance: none;
        background-color: $xp-100;
        margin: 0;
        aspect-ratio: 2/1;
        position: relative;
        justify-items: flex-start;
        overflow: hidden;
        &::before {
          aspect-ratio: 1/1;
          content: "";
          background: $t-gra;
          border: $px-01-5 solid $t4;
          position: absolute;
          border-radius: $px-03;
          box-shadow: $standard-shadow-002;
          margin: $px-01-5;
          height: calc($spacing-vertical-1-5 - ($px-01-5 * 4));
        }
      }
      &[type="checkbox"]:checked {
        justify-items: flex-end;
        &::before {
          margin: $px-01-5;
          box-shadow: $standard-shadow;
          aspect-ratio: 1/1;
          content: "";
          background: $xp-gra;
          border: $px-01-5 solid $xp-sec;
          position: absolute;
          border-radius: $px-03;
          height: calc($spacing-vertical-1-5 - ($px-01-5 * 4));
        }
      }
      &[type="radio"] {
        border-radius: 50%;
        display: flex;
        appearance: none;
        -webkit-appearance: none;
        background-color: $xp-100;
        margin: 0;
        aspect-ratio: 1/1;
        position: relative;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        &::before {
          aspect-ratio: 1/1;
          content: "";
          background: $t-gra;
          border: $px-01-5 solid $t4;
          position: absolute;
          border-radius: 50%;
          box-shadow: $standard-shadow-002;
          height: calc($spacing-vertical-1-5 / 1.5);
          flex-grow: 1;
        }
      }
      &[type="radio"]:checked,
      &[type="radio"].is-active {
        &::before {
          flex-grow: 1;
          box-shadow: $standard-shadow;
          aspect-ratio: 1/1;
          content: "";
          background: $xp-gra;
          border: $px-01-5 solid $xp-sec;
          position: absolute;
          border-radius: 50%;
          height: calc($spacing-vertical-1-5 / 1.5);
        }
      }
    }
    select {
      border: $standard-border;
      background-color: $xp-100;
      color: $xp-040;
      border-radius: $px-03;
      outline: none;
    }
  }
  div.form-errors {
    p {
      //font-size: smaller;
    }
  }
}
