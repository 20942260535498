@tailwind base;
@tailwind components;
@tailwind utilities;

@import "src/assets/styles/reset.scss";
@import "src/assets/styles/variables.scss";
@import "src/assets/styles/typography.scss";
@import "src/assets/styles/loader.css";
@import "src/assets/styles/spinner.scss";
@import "src/assets/styles/forms.scss";
@import "src/assets/styles/forms-xperience.scss";
@import "src/assets/styles/cards.scss";
@import "src/assets/styles/button.scss";
@import "src/assets/styles/player.scss";
@import "src/assets/styles/scroll-container.scss";
@import "src/assets/styles/info-block.scss";

section > h1:first-child,
section > h2:first-child,
section > h3:first-child,
section > h4:first-child,
section > h5:first-child,
section > h6:first-child {
  margin-top: 0;
  margin-bottom: $spacing-vertical-1;
}

nav > ul,
nav > ol {
  margin-block: 0 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  list-style-position: inside;
}

div,
section,
nav,
article,
aside,
header,
footer {
  &.grid-xp {
    display: grid;
    gap: $standard-spacer;
    &.gap-05 {
      gap: calc($standard-spacer / 2);
    }
    &.no-gap {
      gap: 0;
    }
    &.no-gap-row {
      row-gap: 0;
    }
    &.no-gap-col {
      column-gap: 0;
    }
    &.align-center {
      align-items: center;
    }
    &.justify-center {
      justify-items: center;
    }
    &.grid-colspan-none {
      grid-column: none;
    }
    &[class*="grid-cols-2"] {
      &.right-heavy {
        grid-template-columns: auto 1fr;
      }
      &.left-heavy {
        grid-template-columns: 1fr auto;
      }
      &.flow {
        grid-auto-rows: auto;
      }
      &.spread {
        & > *:nth-child(even) {
          text-align: right;
          margin-right: 0;
        }
      }
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

div.button-bar {
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  justify-content: flex-end;
  margin-block: $standard-spacer 0;
  gap: calc($standard-spacer / 2);
  & + div.button-bar {
    margin-top: calc($standard-spacer / 2);
  }
  &.m-block-0 {
    margin-block: 0 0;
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
  & > button {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    &.grow-me {
      flex-grow: 1;
    }
  }
  &.align-center {
    text-align: center;
    justify-content: center;
  }
  &.align-left {
    text-align: left;
    justify-content: flex-start;
  }
  &.spread {
    text-align: left;
    justify-content: space-between;
  }
}

p.sub {
  margin: 0.25rem;
  color: $t6;
  font-size: 0.9rem;
}
