@import "variables.scss";

button,
a.button {
  display: flex;
  flex-direction: row;
  padding: 8px $px-20;
  border-radius: $px-15;
  background: $xp-090;
  cursor: pointer;
  position: relative;
  vertical-align: top;
  border: 0;
  font-weight: 600;
  gap: $px-10;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:has(span[class*="mci"]) {
    display: flex;
    column-gap: calc($px-03 * 2.5);
    &:hover {
      span {
        color: $xp-pri;
      }
    }
  }
  &.is-active {
    background-color: $xp-pri;
    color: $xp-100;
  }
  &.justify-right {
    justify-content: flex-end;
  }
  &:hover {
    text-decoration: none;
    span {
      color: $xp-pri;
    }
  }
  &.is-transparent {
    overflow: visible;
    background: none;
    padding: 8px 0;
    &:not(.is-disabled):not(:disabled):hover,
    &:not(.is-disabled):not(:disabled):hover span {
      color: $xp-pri;
    }
  }
  &.is-colored {
    background-color: $xp-pri;
    color: $xp-100;
    &:hover:not(:disabled) {
      background-color: #ffa81a;
      color: $xp-100;
      span {
        color: $xp-100;
      }
    }
    &:active:not(:disabled) {
      background-color: #b36f00;
    }
  }
  &.is-indigo {
    background-color: $xp-020;
    color: $xp-100;
    &:hover {
      background: $xp-gra;
      color: $xp-100;
      span,
      span[class*="mci"] {
        color: $xp-100;
      }
    }
  }
  &.is-light-indigo {
    background-color: $t7;
    color: $xp-100;
    &:hover {
      background: $xp-gra;
      color: $xp-100;
      span,
      span[class*="mci"] {
        color: $xp-100;
      }
    }
  }
  &.is-loading {
    cursor: default;
  }
  &.is-disabled,
  &:disabled {
    opacity: 0.5;
    cursor: default;
    span {
      color: $xp-000;
    }
  }
}

.dark-mode button {
  // background: $xp-010;
  color: $xp-100;
  &.nav-button {
    color: $xp-060;
    &:hover {
      color: $xp-100;
    }
  }
  // &.is-colored {
  //   background: $xp-gra;
  // }
  &.is-transparent {
    background: none;
  }
  &:not(.is-transparent):not(.is-colored):not(.has-message):not(.is-disabled):not(:disabled):hover {
    background: $xp-020;
  }
  &.is-loading:before,
  &.is-colored.is-loading:before {
    border-top: 1px solid $xp-100;
    border-right: 1px solid $xp-100;
  }
  &.has-message {
    background: $xp-000;
    box-shadow: inset 0 0 0 1px $xp-010;

    &.message h5 {
      color: $xp-100;
    }
  }
}
